<template>
  <div class="nosClients">
    <Loader/>
    <video autoplay muted loop id="bg4">
      <source src="../assets/bg4.mp4" type="video/mp4">
      Your browser does not support HTML5 video.
    </video>
    <h2>Ils nous ont fait confiance</h2>
    <div class="conteneurClient">
      <div class="case1">
        <a :href="this.urlCase" target="blank">
          <img class="thumbCase" :src="this.thumbCase" alt="">
          {{ titreCase }}
        </a>
      </div>
      <div class="case2">
        <div class="logosClients" :class="{'active':(client.nom === titreCase)}" v-for="client in clients" :key="client.id">
        <img :src="client.logo" alt="logo client" @click="lienClient(client.logo, client.thumb, client.url, client.nom)">
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.nosClients h2{
  color: #fff;
  padding: 120px 0 0 70px;
  font-size: 2.5em;
}

#bg4 {
  position: fixed;
  right: 0;
  width: 100%;
  min-height: 100%;
  z-index: -1000;
  bottom: 0px;
}

.conteneurClient{
  position: absolute;
  width: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px 5px;
}

.case1, .case2{
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.72);
}

.case1{
  width:30%;
  min-width: 220px;
}

.case2{
  width:66%;
  text-align: center;
}

.case1 a{
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding: 10px;
  text-decoration: none;
  color: #fff;
}

.case1 a img{
  width: 90%;
}

.logosClients{
  display: inline-block;
  margin: 20px;
  cursor: pointer;
}

.logosClients.active{
  background: radial-gradient(circle, rgb(174, 0, 255) 0%, rgba(255,255,255,0) 65%);
}

.logosClients img{
  width: 100px;
}

/*-----------------------------------------------------------------------------------*/

@media screen and (max-width: 1700px) {
  #bg4{
    width: 150%;
    min-width: 100%;
  }
}

@media screen and (max-width: 1000px) {
  #bg4{
    width: initial;
  }
}

@media screen and (max-width: 700px) {
  .conteneurClient{
    position: inherit;
    width: 95%;
    top: 0;
    left: 0;
    transform: translate(0,0);
    margin-bottom: 30px;

    flex-direction: column;
    gap: 15px;
  }
}
</style>

<script>
// @ is an alias to /src
import Loader from '@/components/Loader.vue'

export default {
  components: {
    Loader
  },
  data () {
    return {
      logoCase: require('../assets/logoOrdibessin.png'),
      thumbCase: require('../assets/ordibessin_thumb.png'),
      urlCase: 'https://ordibessin.fr/',
      titreCase: 'OrdiBessin',
      clients: [
        { id: 1, nom: 'OrdiBessin', logo: require('../assets/logoOrdibessin.png'), thumb: require('../assets/ordibessin_thumb.png'), url: 'https://ordibessin.fr/' },
        { id: 2, nom: 'L\'Entre Deux Phares', logo: require('../assets/logolentredeuxphares.png'), thumb: require('../assets/lentredeuxphares_thumb.png'), url: 'https://lentredeuxphares.fr/' }
      ]
    }
  },
  mounted () {
    document.querySelector('.navCheckbox').checked = false
    setTimeout(
      function () {
        document.querySelector('#loader').style.display = 'none'
      }, 2300)
    window.scrollTo(0, 0)
  },
  methods: {
    lienClient (clientLogo, clientThumb, clientUrl, clientNom) {
      this.logoCase = clientLogo
      this.thumbCase = clientThumb
      this.urlCase = clientUrl
      this.titreCase = clientNom
    }
  }
}
</script>
